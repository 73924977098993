import React from 'react';
import PropTypes from 'prop-types';
import TopBar from '../../organisms/TopBar';
import TeamMember from '../../organisms/TeamMember';
import AdditionalMembers from '../../organisms/AdditionalMembers';
import Main from '../../organisms/Main';
import Intro from '../../organisms/Intro';
import Markdown, { MarkdownHtml } from '../../organisms/Markdown';
import SiblingNavigation from '../../organisms/SiblingNavigation';
import Footer from '../../organisms/Footer';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  intro: {},
  main: {
    marginBottom: theme.stack.large
  },
  additionalMembers: {
    borderTop: `4px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.up('lg')]: {
      borderTop: 'unset',
      '&::before': {
        display: 'flex',
        content: '""',
        backgroundColor: theme.palette.grey[200],
        height: 4,
        width: '100vw',
        position: 'relative',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
        bottom: theme.inset.medium
      }
    }
  }
}));

const Team = props => {
  const {
    teamMembers,
    additionalMembers,
    markdownComponent,
    TopBarProps,
    IntroProps,
    MarkdownProps,
    SiblingNavigationProps,
    FooterProps
  } = props;
  const MarkdownComponent = markdownComponent || Markdown;
  const classes = useStyles(props);

  return (
    <>
      <TopBar {...TopBarProps} />
      <Main className={classes.main}>
        <Intro className={classes.intro} {...IntroProps} />
        <MarkdownComponent {...MarkdownProps} />
        {teamMembers.map((memberProps, index) => (
          <TeamMember key={index} {...memberProps} />
        ))}
        {additionalMembers && (
          <AdditionalMembers
            className={classes.additionalMembers}
            {...additionalMembers}
          />
        )}
      </Main>
      {SiblingNavigationProps && (
        <SiblingNavigation {...SiblingNavigationProps} />
      )}
      <Footer {...FooterProps} />
    </>
  );
};

Team.propTypes = {
  teamMembers: PropTypes.arrayOf(PropTypes.object),
  additionalMembers: PropTypes.object,
  markdownComponent: PropTypes.oneOf([Markdown, MarkdownHtml]),
  TopBarProps: PropTypes.object.isRequired,
  IntroProps: PropTypes.object.isRequired,
  MarkdownProps: PropTypes.object.isRequired,
  SiblingNavigationProps: PropTypes.object,
  FooterProps: PropTypes.object.isRequired
};

export default Team;
