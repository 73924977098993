import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import avatarShape from '../../../img/avatarShape.svg';
import avatar from '../../../img/avatar.svg';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useClasses = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${avatarShape})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: 275,
    height: 275,
    overflow: 'hidden',
    '& $image': {
      width: '175px !important',
      height: '175px !important',
      margin: [[65, 50, 35, 50]],
      borderRadius: '100%',
      '& img': {
        borderRadius: '100%'
      }
    },
    [theme.breakpoints.up('lg')]: {
      width: 300,
      height: 300,
      '& $image': {
        width: '200px !important',
        height: '200px !important',
        margin: [[70, 56, 30, 44]]
      }
    }
  },
  image: {}
}));

const Avatar = props => {
  const { imgProps, className, ImgComponent = GatsbyImage } = props;
  const classes = useClasses(props);

  return (
    <div className={clsx(classes.root, className)}>
      {imgProps ? (
        <ImgComponent className={classes.image} {...imgProps} />
      ) : (
        <img className={classes.image} src={avatar} alt="Avatar" />
      )}
    </div>
  );
};

Avatar.propTypes = {
  imgProps: PropTypes.object,
  className: PropTypes.string,
  ImgComponent: PropTypes.elementType
};

Avatar.defaultProps = {};

export default Avatar;
