import { graphql } from 'gatsby';
import { frontmatter } from '../helper/data';
import { markdownToHtml } from '../helper/transformation';

export function mapToProps(data = [], isCms = false) {
  const fmData = isCms ? data : frontmatter(data);
  const additionalMembersData = fmData.additionalMembers || null;

  if (!additionalMembersData) {
    return;
  }

  return {
    heading: additionalMembersData.heading,
    descriptionMarkup: additionalMembersData.descriptionMarkdown
      ? markdownToHtml(additionalMembersData.descriptionMarkdown, [
          'a',
          'p',
          'strong',
          'b',
          'em',
          'i'
        ])
      : '',
    persons:
      additionalMembersData.persons &&
      additionalMembersData.persons.map(
        person =>
          [
            person.honorificPrefix,
            person.honorificSuffix,
            person.givenName,
            person.additionalName,
            person.familyName
          ].join(' ') + (person.degree ? ` (${person.degree})` : '')
      )
  };
}

export const frontmatterFragment = graphql`
  fragment AdditionalMembers on MarkdownRemarkFrontmatter {
    additionalMembers {
      descriptionMarkdown
      heading
      persons {
        familyName
        givenName
        honorificPrefix
        degree
      }
    }
  }
`;
