import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Link from '../../atoms/Link';
import Avatar from '../../atoms/Avatar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/styles';
import { gridSpanCalc } from '../../base/Layout/Grid';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    padding: [[theme.inset.medium, theme.grid.xs.inset]],
    display: 'flex',
    flexDirection: 'row',
    '& $role': {
      color: theme.palette.text.mainLowContrast
    },
    '& $fullName': {
      color: theme.palette.text.headline,
      marginBottom: theme.stack.small
    },
    '& $description': {
      color: theme.palette.text.main,
      marginBottom: theme.stack.medium,
      '& a': {
        textDecoration: 'underline',
        color: theme.palette.primary.dark,
        '@media(hover:hover)': {
          '&:hover': {
            color: theme.palette.text.main
          }
        }
      },
      '& p:first-child': {
        marginTop: 0
      },
      '& p:last-child': {
        marginBottom: 0
      }
    },
    '& $avatar': {
      display: 'block',
      margin: [[0, 'auto']]
    },
    '& $linkList': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.stack.medium,
      '&:last-child': {
        marginBottom: 0
      }
    },
    '& $link': {
      marginBottom: theme.stack.xsmall
    },
    '& $linkContainer': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& $content': {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.grid.sm.inset,
      paddingRight: theme.grid.sm.inset,
      paddingBottom: theme.stack.large,
      '& $content': {
        paddingLeft: theme.inset.small,
        paddingRight: theme.inset.small,
        flexGrow: 1
      },
      '& $linkContainer': {
        flexDirection: 'row'
      },
      '& $linkList': {
        marginBottom: 0,
        flex: 1
      }
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.grid.md.inset,
      paddingRight: theme.grid.md.inset,
      justifyContent: 'space-between',
      '& $content': {
        maxWidth: 700,
        paddingLeft: 0,
        paddingRight: 0,
        marginRight: theme.inline.small,
        marginLeft: gridSpanCalc(theme, 'md', 1)
      }
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.grid.lg.inset,
      paddingRight: theme.grid.lg.inset,
      '& $content': {
        maxWidth: 'unset',
        paddingLeft: theme.inset.medium,
        paddingRight: theme.inset.medium,
        marginLeft: gridSpanCalc(theme, 'lg', 1)
      }
    }
  },
  role: {},
  fullName: {},
  description: {},
  avatar: {},
  link: {},
  linkList: {},
  linkContainer: {},
  aside: {},
  content: {}
}));

const telToE164 = tel => tel.replace(/(\([^)]*\))| |-/g, '');

const TeamMember = props => {
  const {
    role,
    fullName,
    description,
    descriptionMarkup,
    email,
    telefoneNumber,
    orcid,
    download,
    componentsProps,
    components,
    className
  } = props;
  const classes = useStyles(props);
  const AvatarComponent = components.avatar ?? Avatar;
  const linkList = links => {
    if (
      !links ||
      links.length === 0 ||
      links.filter(l => l !== null).length === 0
    ) {
      return;
    }
    return (
      <div className={classes.linkList}>
        {links.map(
          (linkProps, index) =>
            linkProps &&
            Object.entries(linkProps).length && (
              <Link key={index} className={classes.link} {...linkProps} />
            )
        )}
      </div>
    );
  };
  const emailLinkProps = email
    ? {
        variant: 'internal',
        children: 'Send E-Mail',
        to: `mailto:${email}?subject=Hello CJT Lab`
      }
    : null;
  const telLinkProps = telefoneNumber
    ? {
        variant: 'internal',
        children: telefoneNumber,
        to: `tel:${telToE164(telefoneNumber)}`
      }
    : null;
  const orcidLinkProps = orcid
    ? (function () {
        const { label, url } = orcid;
        return {
          variant: 'orcid',
          children: label,
          to: url
        };
      })()
    : null;
  const downloadLinkProps = download
    ? (function () {
        const { label, url } = download;
        return {
          variant: 'download',
          children: label,
          to: url
        };
      })()
    : null;
  const avatar = () => (
    <AvatarComponent {...componentsProps.avatar} className={classes.avatar} />
  );

  // TODO jsonld for person
  return (
    <section className={clsx(classes.root, className)}>
      <div className={classes.content}>
        {role && (
          <Typography className={classes.role} variant="overline">
            {role}
          </Typography>
        )}
        <Typography className={classes.fullName} variant="h4">
          {fullName}
        </Typography>
        <Hidden mdUp implementation="css">
          {avatar()}
        </Hidden>
        {descriptionMarkup && (
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: descriptionMarkup }}
            className={classes.description}
            variant="body1"
          />
        )}
        {description && (
          <Typography className={classes.description} variant="body1">
            {description}
          </Typography>
        )}
        <div className={classes.linkContainer}>
          {linkList([orcidLinkProps, downloadLinkProps])}
          {linkList([emailLinkProps, telLinkProps])}
        </div>
      </div>
      <Hidden implementation="css" only={['xs', 'sm']}>
        <div className={classes.aside}>{avatar()}</div>
      </Hidden>
    </section>
  );
};

TeamMember.propTypes = {
  role: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  description: PropTypes.string,
  descriptionMarkup: PropTypes.string,
  email: PropTypes.string,
  telefoneNumber: PropTypes.string,
  download: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),
  orcid: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),
  componentsProps: PropTypes.shape({
    avatar: PropTypes.object
  }),
  components: PropTypes.shape({
    avatar: PropTypes.elementType
  }),
  className: PropTypes.string
};

TeamMember.defaultProps = {
  componentsProps: {
    avatar: {}
  },
  components: {
    avatar: Avatar
  }
};

export default TeamMember;
