import Meta from '../../components/Meta.js';
import PropTypes from 'prop-types';
import React from 'react';
import TeamPageTemplate from '../../components/templates/TeamPage';
import withRoot from '../../hoc/withRoot';
import { BreadcrumbJsonLd, JsonLd } from 'gatsby-plugin-next-seo';
import { MarkdownHtml } from '../../components/organisms/Markdown';
import { getProps as footerProps } from '../utils/mapper/footer';
import { graphql } from 'gatsby';
import { mapToProps as mapToIntroProps } from '../utils/mapper/intro';
import { mapToProps as mapToMarkdownProps } from '../utils/mapper/markdown';
import { mapToProps as mapToMetaProps } from '../utils/mapper/meta';
import { mapToProps as mapToSiblingNavProps } from '../utils/mapper/siblingNavigation';
import { mapToProps as mapToAdditionalMembersProps } from '../utils/mapper/additionalMembers';
import { mapToProps as mapToTeamMembersProps } from '../utils/mapper/teamMembers';
import { mapToProps as mapToTopbarProps } from '../utils/mapper/topbar';
import { siteMetadata, frontmatter } from '../utils/helper/data';

const TeamPage = ({ location, data }) => {
  const smData = siteMetadata(data);
  const fmData = frontmatter(data);

  return (
    <>
      <Meta {...mapToMetaProps(data, location)} />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: smData.title,
            item: smData.siteUrl
          },
          {
            position: 2,
            name: 'Team',
            item: `${smData.siteUrl}/team`
          },
          {
            position: 3,
            name: fmData.title,
            item: smData.siteUrl + location.pathname
          }
        ]}
      />
      {fmData.persons &&
        fmData.persons.map((person, index) => (
          <JsonLd
            key={index}
            json={{
              '@context': 'http://schema.org/',
              '@type': 'Person',
              jobTitle: person.role,
              givenName: person.givenName,
              additionalName: person.additionalName,
              familyName: person.familyName,
              honorificPrefix: person.honorificPrefix,
              honorificSuffix: person.honorificSuffix,
              telephone: person.telephone,
              email: person.email,
              image: person.image?.file?.childImageSharp?.gatsbyImageData?.src
                ? smData.siteUrl +
                  person.image?.file?.childImageSharp?.gatsbyImageData?.src
                : null
            }}
          />
        ))}

      <TeamPageTemplate
        markdownComponent={MarkdownHtml}
        teamMembers={mapToTeamMembersProps(data)}
        additionalMembers={mapToAdditionalMembersProps(data)}
        TopBarProps={mapToTopbarProps(data, location)}
        IntroProps={mapToIntroProps(data)}
        MarkdownProps={mapToMarkdownProps(data)}
        FooterProps={footerProps()}
        SiblingNavigationProps={mapToSiblingNavProps(data, location)}
      />
    </>
  );
};
TeamPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
};

TeamPage.defaultProps = {
  // propName: defaultValue
};

export default withRoot(TeamPage);

export const pageQuery = graphql`
  query TeamPageTemplate($id: String!, $templateKey: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        ...MetaSiteMetadata
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        ...Intro
        ...Persons
        ...AdditionalMembers
        ...MetaFrontMatter
      }
      ...MarkdownContent
    }
    ...TopBar
    ...SiblingNavigation
  }
`;
