import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { gridSpanCalc } from '../../base/Layout/Grid';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    padding: [
      [theme.inset.medium, theme.grid.xs.inset, 0, theme.grid.xs.inset]
    ],
    '& $heading': {
      color: theme.palette.text.headline,
      marginBottom: theme.stack.small
    },
    '& $description': {
      color: theme.palette.text.main,
      marginBottom: theme.stack.small
    },
    '& $personList': {
      padding: 0,
      listStyle: 'none',
      color: theme.palette.text.main,
      '& > li': {
        marginBottom: theme.stack.small,
        '&:last-child': {
          marginBottom: 0
        }
      }
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.grid.sm.inset,
      paddingRight: theme.grid.sm.inset,
      '& $heading, $description, $personList': {
        paddingLeft: theme.inset.small,
        paddingRight: theme.inset.small
      }
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.grid.md.inset,
      paddingRight: theme.grid.md.inset,
      '& $heading, $description, $personList': {
        maxWidth: 700,
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: gridSpanCalc(theme, 'md', 1)
      }
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.grid.lg.inset,
      paddingRight: theme.grid.lg.inset,
      '& $heading, $description, $personList': {
        maxWidth: 780,
        paddingLeft: theme.inset.medium,
        paddingRight: theme.inset.medium
      }
    }
  },
  content: {},
  heading: {},
  description: {},
  personList: {}
}));

const AdditionalMembers = props => {
  const { heading, persons, description, descriptionMarkup, className } = props;
  const classes = useStyles(props);

  return (
    <section className={clsx(classes.root, className)}>
      <Typography className={classes.heading} variant="h4">
        {' '}
        {heading}
      </Typography>
      {descriptionMarkup && (
        <Typography
          component="div"
          dangerouslySetInnerHTML={{ __html: descriptionMarkup }}
          className={classes.description}
          variant="body1"
        />
      )}
      {description && (
        <Typography className={classes.description} variant="body1">
          {description}
        </Typography>
      )}
      {persons && persons.length > 0 && (
        <ul className={classes.personList}>
          {persons.map((person, index) => (
            <Typography key={index} component="li" variant="h5">
              {person}
            </Typography>
          ))}
        </ul>
      )}
    </section>
  );
};

AdditionalMembers.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  descriptionMarkup: PropTypes.string,
  persons: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
};

AdditionalMembers.defaultProps = {};

export default AdditionalMembers;
