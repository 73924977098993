import { graphql } from 'gatsby';
import { frontmatter } from '../helper/data';
import { markdownToHtml } from '../helper/transformation';
import { getImage } from 'gatsby-plugin-image';

export function mapToProps(data = [], isCms = false) {
  const fmData = isCms ? data : frontmatter(data);
  const personsData = fmData.persons || [];

  if (personsData.length <= 0) {
    return [];
  }

  return personsData.map(person => {
    const {
      role,
      givenName,
      additionalName,
      familyName,
      honorificPrefix,
      honorificSuffix,
      degree,
      telephone,
      email,
      orcid,
      textMarkdown,
      image,
      download
    } = person;

    return {
      role,
      fullName:
        [
          honorificPrefix,
          honorificSuffix,
          givenName,
          additionalName,
          familyName
        ].join(' ') + (degree ? ` (${degree})` : ''),
      descriptionMarkup: textMarkdown
        ? markdownToHtml(textMarkdown, ['a', 'p', 'strong', 'b', 'em', 'i'])
        : '',
      email,
      telefoneNumber: telephone,
      orcid: orcid && {
        label: orcid,
        url: `https://orcid.org/${orcid}`
      },
      download: download && {
        label: `${download?.label} (${download?.file?.prettySize})`,
        url: download?.file?.publicURL
      },
      componentsProps: image
        ? {
            avatar: {
              ImgComponent: isCms ? 'img' : undefined,
              imgProps: isCms
                ? {
                    alt: image?.altText,
                    src: image?.file
                  }
                : {
                    alt: image?.altText,
                    image: getImage(image?.file)
                  }
            }
          }
        : { avatar: {} }
    };
  });
}

export const frontmatterFragment = graphql`
  fragment Persons on MarkdownRemarkFrontmatter {
    persons {
      role
      givenName
      additionalName
      familyName
      honorificPrefix
      honorificSuffix
      degree
      telephone
      email
      orcid
      textMarkdown
      download {
        label
        file {
          publicURL
          prettySize
        }
      }
      image {
        altText
        file {
          childImageSharp {
            gatsbyImageData(width: 200, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
